import { useStore } from 'vuex';
import axios from '@/api/request';
function useLocation() {
  const store = useStore();
  const geoLocation = computed(() => {
    return store.state.user.geoLocation || 'All';
  });
  const geoLocations = ref([]);
  function getGeoLocationInfo(latitude, longitude) {
    return axios.get('/home/LocateState', {
      params: {
        lng: longitude,
        lat: latitude
      },
      isNotLoading: true
    });
  }

  // Obtain the user's geographical location
  function getGeoLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          getGeoLocationInfo(latitude, longitude).then(res => {
            if (res && res.data && res.data.state) {
              store.commit('user/setUserGeoLocation', res.data.state);
              resolve(res.data.state);
            }
          });
        }, function (error) {
          console.error(error);
          reject('User refuses to obtain geographic location.');
        });
      } else {
        reject(new Error('The device does not support obtaining geographical location.'));
      }
    });
  }
  function setGeoLocation(geoLocationInfo) {
    store.commit('user/setUserGeoLocation', geoLocationInfo);
  }

  // function getGeoLocations (data, hasAll) {
  //   axios.get('/home/StateList', {
  //     params: data,
  //     isNotLoading: true
  //   }).then(res => {
  //     if (res && res.data) {
  //       geoLocations.value = res.data
  //       if (hasAll) {
  //         geoLocations.value.unshift({
  //           state_abbr: 'All',
  //           state_name: 'All',
  //         })
  //       }
  //     }
  //   })
  // }
  function getGeoLocations(data, hasAll) {
    // 检查本地是否已有缓存数据
    // const cachedGeoLocations = localStorage.getItem('geoLocations');
    // 首先检查缓存是否有效
    const cachedData = getCachedGeoLocations();
    if (cachedData) {
      // 如果有缓存，直接使用缓存数据
      geoLocations.value = cachedData;
      if (hasAll) {
        geoLocations.value.unshift({
          state_abbr: 'All',
          state_name: 'All'
        });
      }
      return;
    }

    // 如果没有缓存，发送请求获取数据
    axios.get('/home/StateList', {
      params: data,
      isNotLoading: true
    }).then(res => {
      if (res && res.data) {
        geoLocations.value = res.data;
        if (hasAll) {
          geoLocations.value.unshift({
            state_abbr: 'All',
            state_name: 'All'
          });
        }
        // 将数据保存到本地缓存，设置过期时间（如 1 小时）
        localStorage.setItem('geoLocations', JSON.stringify(res.data));
        localStorage.setItem('geoLocationsTimestamp', Date.now().toString());
      }
    });
  }

  // 从缓存中读取并检查是否过期
  function getCachedGeoLocations() {
    const cachedGeoLocations = localStorage.getItem('geoLocations');
    const cachedTimestamp = localStorage.getItem('geoLocationsTimestamp');
    if (cachedGeoLocations && cachedTimestamp) {
      const timestamp = parseInt(cachedTimestamp, 10);
      const oneHour = 60 * 60 * 1000; // 1小时
      const currentTime = Date.now();

      // 如果缓存未过期，返回缓存数据
      if (currentTime - timestamp < oneHour) {
        return JSON.parse(cachedGeoLocations);
      } else {
        // 如果缓存过期，清除缓存并返回 null
        localStorage.removeItem('geoLocations');
        localStorage.removeItem('geoLocationsTimestamp');
        return null;
      }
    }
    return null;
  }
  return {
    geoLocation,
    geoLocations,
    getGeoLocation,
    setGeoLocation,
    getGeoLocations
  };
}
export default useLocation;